import React from 'react';
// import PropTypes from 'prop-types';
import Modal from '../Modal';
import DidYouKnowCard from './DidYouKnowCard';
import './did-you-know.scss';
import data from '../../../../Content/dyk-modal.json';
import wiseJourneyIcon from '../../../images/icons/dyk-journey.png';
import wiseTipsIcon from '../../../images/icons/dyk-wisetips.png';
import superHonestlyIcon from '../../../images/icons/dyk-superhonestly.png';
import { Link } from "gatsby";
import tracker from '../../../../utils/tracker';

const icons = [wiseJourneyIcon, wiseTipsIcon, superHonestlyIcon]
const dykCards = data.cards.map((card, idx) => ({
    ...card,
    icon: icons[idx]
}))
data.cards = dykCards;

const DidYouKnowModal = (props) => {
    const { openCls, setOpenCls } = props;
    const handleClose = ()=>{
        document.documentElement.classList.remove("pw-no-scroll");
        setOpenCls('w3-hide')
    }
    if (!data) {
        return null;
    }

    return (
        <Modal openCls={openCls}
            customStyle={{ maxWidth: '860px' }}
            customClass="pw-dyk-modal"
            modalStyle={{ zIndex: 50 }}
        >
            <div className="w3-display-container">
                <div
                    className="pw-dyk-close"
                    onClick={() =>handleClose()}
                />
            </div>
            <div className="w3-content pw-dyk-container">
                <div className="pw-dyk-main-content">
                    <div className="pw-dyk-title">
                        {data.title}
                    </div>
                    <div className="pw-dyk-top-desc">
                        {data.topDescription}
                    </div>

                    <div className="w3-row pw-dyk-cards">
                        {data.cards.map(card => (
                            <DidYouKnowCard
                                key={`card-${card.title}`}
                                data={card}
                            />
                        ))}
                    </div>
                    <div className="w3-row">
                        <div className="pw-dyk-bottom-desc">
                            {data.bottomDescription}
                        </div>
                    </div>

                    <div className="w3-row pw-dyk-signup">
                        <div className="w3-round-xxlarge pw-dyk-reg-btn">
                            <Link onClick={() => tracker.trackEvent('Did you know (CTA within Pop-up)', 'Click-Signup', tracker.getPageLabel())}
                                to="/auth" className="pw-dyk-reg-text">
                                Sign me up! <span className="pw-reg-btn-arrow" />
                            </Link>
                        </div>

                    </div>
                </div>
            </div>

        </Modal>
    )

}
export default DidYouKnowModal