import React from 'react';

const DidYouKnowCard = (props) => {
    const { data } = props;
    return (
        <div className="w3-col pw-dyk-card">
            <div className="pw-dyk-img-container">
                <img loading="lazy" src={data.icon}
                    alt={data.title}
                    className="pw-dyk-img"
                />
            </div>
            <div className="pw-dyk-content">
                <div className="pw-dyk-c-title">
                    {data.title}
                </div>
                <div className="pw-dyk-c-desc">
                    {data.description}
                </div>
            </div>
        </div>
    )
}

export default DidYouKnowCard;